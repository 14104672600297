import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"


export const SliderData2 = [
    {
        name: "Dan Hill, Jenwest Construction",
        description: "Thank you for the service and a job well done at the Royal Columbian Hospital. I will keep you in mind for our future cutting and coring."

    },
    {
        name: "Client Two",
        description: " We can provide coring services for all sizes of projects from large industrial & commercial to small residential jobs."

    },
    {
        name: "Client Three",
        description: "We offer wall cutting, slab cutting, & floor cutting for industrial, commercial and residential projects."
        
    },
] 

