import React from "react"
import Links from "../constants/links"
import SocialLinks from "../constants/socialLinks"
import { FaTimes } from "react-icons/fa"
import styled from "styled-components"
// import xIcon from "../assets/Close.svg"
import xIcon from "../assets/X.png"

import PageLinks from "../constants/links"
import { Link } from "gatsby"
// import LogoMobile from "../assets/Mobile.svg"
import LogoMobile from "../assets/scancore-logo 1.svg"




const Sidebar = ({isOpen, toggleSidebar}) => {
  return(

    <aside className={`sidebar ${isOpen ? "show-sidebar" : ""}`} style={{transition: "all .5s ease-in-out"}}>

       <SidebarContainer >
      <MenuInner>
           <div>
               <img src={LogoMobile} alt="" /> 
           </div>
           <div onClick={toggleSidebar}>
               <img src={xIcon} alt="" /> 
           </div>
        </MenuInner>

       <MenuNav>
             <Link to={"/services/"} style={{textDecoration: "none", color: "#2287B2", fontWeight: "bold"}}>
               <Text>Services</Text>
             </Link>

             <Link to={"/about/"} style={{textDecoration: "none", color: "#2287B2"}}>
                <Text style={{paddingTop: "15px",paddingBottom: "15px"}}>About</Text>
             </Link>

               <Link to={"/contact/"} style={{textDecoration: "none", color: "#2287B2"}}>
                 <Text>Contact</Text>
               </Link>
           </MenuNav>

           <Address>
               <TextBox style={{color: "#2287B2"}}>
                   5148 Still Creek Avenue,
               </TextBox>
               <TextBox style={{color: "#2287B2"}}>
                  Burnaby, BC V5C 4E4
               </TextBox>
           </Address>

           <Phone>
                <TextBox style={{color: "#2287B2"}}>
                   604-879-2425
               </TextBox>
               <TextBox style={{color: "#2287B2"}}>
                  <A href="mailto:scancore@telus.net" style={{color: "#2287B2", textDecoration: "none"}}>scancore@telus.net</A>
               </TextBox>
           </Phone>
    





    </SidebarContainer>


    </aside>
   

  )
}

const A = styled.a`
`

const SidebarContainer = styled.aside`
position: fixed;
// margin-top: 100px;
z-index: 999;
width: 100%;
height: 100%;
background: #FFF;
display: grid;
align-items: center;
top:0;
left: 0;
transition: 0.3s ease-in-out;
font-family: GT America Regular;
// opacity: ${({isOpen}) => (isOpen ? '100%' : "0")};
// top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
top: 0;
`
const CloseIcon = styled.img`
background-color: #2287B2;
` 
const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`
const MenuInner = styled.div`
padding-left: 16px;
padding-right: 16px;
padding-top: 16px;
display: flex;
justify-content: space-between;
height: 64px;
position: fixed;
top: 0;
width: 100%;
background: transparent;

`

const MenuNav = styled.div`
padding-top: 90px;
font-size: 56px;
line-height:64px;
color: white;
font-family;
font-weight: 700;
`

const Text = styled.div`
text-align: center;
cursor: pointer;

&:hover {
  color: #0D6DA3;
  transition: 0.2s ease-in-out;
}
`

const Address = styled.div`
font-weight: 400;
font-size: 16px;
line-height: 24px;
`

const TextBox = styled.div`
// color: white;
text-align: center;
`
const Phone = styled.div`
font-weight: 400;
font-size: 16px;
line-height: 24px;
`

export default Sidebar
