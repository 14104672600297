import React from 'react'
import styled from "styled-components"
// import Logo from "../assets/Desktop.svg"
import Logo from "../assets/scancore-logo 1.svg"
// import Logo2 from "../assets/Mobile.svg"
import Logo2 from "../assets/scancore-logo 1.svg"
import MenuIcon from '../assets/Hamburger.svg'
import { Button } from '@material-ui/core'
import { Link } from "gatsby"



const Navbar = ({toggleSidebar}) => {
  return (
    <>
    <NavBox>
	    <NavToggle type="checkbox"/>

      <ImgContainer>
      <Link to={"/"} style={{textDecoration: "none", color: "white"}}>
          <Img2 src={Logo2} alt="logo" href="home"/>
      </Link>
      <Link to={"/"} style={{textDecoration: "none", color: "white"}}>
         <Img1 src={Logo} alt="logo" href="home"/>
      </Link>
      </ImgContainer>

      <UlLinks>
        <Li>
          <Link to={"/services"} style={{textDecoration: "none", color: "#2287B2"}}>
               <A>SERVICES</A>
          </Link>
        </Li>
        <Li>
           <Link to={"/about"} style={{textDecoration: "none", color: "white"}}>
               <A>ABOUT</A>
          </Link>
        </Li>
        <LiB>
          <Link to={"/contact"} style={{textDecoration: "none", color: "red"}}>
            <Buton>
              CONTACT
            </Buton>
          </Link>
        </LiB>
      </UlLinks>

      <IconBurger onClick={toggleSidebar}>
        <Line></Line>
        <Line></Line>
        <Line></Line>
      </IconBurger>

    </NavBox>

    </>
  )
}


const IconBurger = styled.label`
display: none;
position: absolute;
right: 5%;
top: 50%;
transform: translateY(-50%);

@media screen and (max-width: 700px) {
display: block;
}
`

const Line = styled.div`
width: 36px;
height: 3px;
// background-color: #fff;
background-color: #2287B2;
margin: 5px;
border-radius: 3px;
transition: all .3s ease-in-out;
`

const Buton = styled.button`
padding: 12; 
border: 1px solid #2287B2;
box-sizing: border-box;
border-radius: 4px;
width: 105px;
height: 40px;
background-color: #FFF;
color: #2287B2;
fontSize: 14px;
line-height: 16px;
font-family: "GT America Regular";
font-weight: 700;

@media screen and (max-width: 700px) {
margin-right: 60px;
}

`

const A = styled.a`
display: block;
padding: 1.1em;
color: #2287B2;
text-decoration: none;
font-size: 14px;
line-height: 16px;
font-family: "GT America Regular";
font-weight: 700;

@media screen and (max-width: 700px) {
display: none;
}
`
const B = styled.a`
display: block;
padding: 1.1em;
color: #fff;
text-decoration: none;
font-size: 14px;
line-height: 16px;
font-family: "GT America Regular";
font-weight: 700;

// @media screen and (max-width: 700px) {
// display: none;
// }
`

const Li = styled.li`
list-style: none;
`
const LiB = styled.li`
list-style: none;

@media screen and (max-width: 700px) {
margin-right: 16px;
}

`
const UlLinks = styled.ul`
float: right;
padding: 0;
margin: 0;
width: 60%;
height: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
`

const Img2 = styled.img`
display: none;

@media screen and (max-width: 700px) {
display: block;
}
`

const Img1 = styled.img`
@media screen and (max-width: 700px) {
display: none;
}
`

const ImgContainer = styled.div`
float: left;
width: 40%;
height: 100%;
display: flex;
align-items: center;
font-size: 24px;
color: #fff;

@media screen and (max-width: 700px) {
float: left;
}
`

const NavToggle = styled.input`
position: absolute;
top: -100px;
`

const NavBox = styled.nav`
position: fixed;
z-index: 10;
left: 0;
right: 0;
top: 0;
height: 64px;
// background-color: #0D5FFF;
background-color: #FFF;
margin-left: 64px;
margin-right: 64px;
padding-left: 24px;
padding-right: 24px;

@media screen and (max-width: 1110px) {
margin-left: 0px;
margin-right: 0px;
padding-left: 24px;
padding-right: 24px;
}

@media screen and (max-width: 700px) {
margin-left: 0px;
margin-right: 0px;
padding-left: 16px;
padding-right: 16px;
}
`

export default Navbar