import React from 'react'
import styled from "styled-components"
import Logo from "../assets/Desktop.svg"
import Logo2 from "../assets/Mobile.svg"
import MenuIcon from '../assets/Hamburger.svg'
import { Button } from '@material-ui/core'
import Team from "../assets/Teamcareers.svg"
import Team1 from "../assets/contact-image-2_d.jpg"
import Team2 from "../assets/contact-image-2_m.jpg"




const Careers = () => {
  return (
    <>
    <CareersBox>
        <ImgBox>
           <ImgCareers src={Team1} alt=""/>
        </ImgBox>
        
        <TextBox>
            <Tittle>
                Careers
            </Tittle>
            <Text1>
                Scancore Ltd. is currently looking for experienced operators to join our team! 
            </Text1>
            <Text2>
                We are based out of Burnaby, BC offering competitive hourly wages and extended health benefits 
            </Text2>
            <Text3 >
              Must have a valid BC Class 5 drivers license    
            </Text3>
            <Text4 >
              Please send your resume to:
            </Text4>
            <Text5>
              <A href="mailto:scancore@telus.net" style={{color: "#0D6DA3", textDecoration: "none"}}>scancore@telus.net</A>
            </Text5>
            <Tittle2>
                Full Time Job Positions include:
            </Tittle2>

            <Ul>
              <li>
            <Text6 >
              GPR/Utility Scanning Technicians 
            </Text6>
              </li>
              <li>
                 <Text6 >
                  Core Drill Operators  
                 </Text6>
              </li>

              <li>
                 <Text6>
                     Wall Saw Operators
                 </Text6>
              </li>

              <li>
                <Text6>
                  Slab Saw Operators 
                </Text6>
              </li>

              <li>
                <Text7 >
                  Laborers 
                </Text7>
              </li>

            </Ul>
            
           
           
            
            
             {/* <NavBtn>
               <NavBtnLink>APPLY NOW</NavBtnLink>
            </NavBtn> */}
        </TextBox>
    </CareersBox>
    </>
  )
}

const Ul = styled.ul`
padding-left: 12px;

@media screen and (max-width: 700px) {
padding-left: 16px;
}
`

const CareersBox = styled.div`
display: flex;

@media screen and (max-width: 700px) {
display: block;
}
`

const ImgBox = styled.div`
width: 50%;

@media screen and (max-width: 700px) {
width: 100%;
}
`

const ImgCareers = styled.img`
width: 100%;
height: 100%;
`

const TextBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background-color: #fff;
width: 50%;
padding-left: 100px;
padding-right: 200px;
vertical-align: middle;

@media screen and (max-width: 700px) {
width: 100%;
padding-top: 16px;
padding-bottom: 30px;
display: block;
justify-content: flex-start;
vertical-align: none;

}

@media screen and (max-width: 800px) {
padding-right: 16px;
padding-left: 16px;

}

@media screen and (max-width: 1110px) {
// padding-left: 80px;
// padding-right: 80px;


}
`
const A = styled.a`
text-color: #000000;
`
const Tittle = styled.h1`
color: #000000;
font-size: 40px;
line-height: 48px;
font-weight: 700;
font-family: GT America Bold;
margin-bottom: 16px; 
margin-top: 60px;
vertical-align: middle;


@media screen and (max-width: 700px) {
font-size: 24px;
line-height: 32px; 
margin-top: 10px;
}

@media screen and (max-width: 1110px) {
font-size: 24px;
line-height: 32px; 
}
`

const Tittle2 = styled.h1`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Bold; 
margin-bottom: 15px; 

@media screen and (max-width: 700px) {
font-size: 16px;
line-height: 24px;
margin-bottom: 20px; 

}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;

}

`
const Text1 = styled.p`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 30px;

@media screen and (max-width: 700px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
}
`

const Text2 = styled.p`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 10px; 

@media screen and (max-width: 700px) {
font-size: 16px;
line-height: 24px; 
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;
}
`
const Text3 = styled.p`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 30px; 


@media screen and (max-width: 700px) {
margin-bottom: 24px;
font-size: 16px;
line-height: 24px;  
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;
}
`

const Text4 = styled.p`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 5px; 


@media screen and (max-width: 700px) {
margin-bottom: 24px;
font-size: 16px;
line-height: 24px;  
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;

}


`
const Text5 = styled.p`
// color: #000000;
text-color: #0D6DA3;

line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 40px;
textDecoration: none; 


@media screen and (max-width: 700px) {
padding-bottom: 16px;
font-size: 16px;
line-height: 24px;  
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;

}

&:hover {
underline: #0033FF;
color: #0033FF;
cursor: pointer;
}
`

const Text6 = styled.p`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 5px; 


@media screen and (max-width: 700px) {
margin-bottom: 24px;
font-size: 16px;
line-height: 24px;  
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
margin-bottom: 8px;
}
`

const Text7 = styled.p`
color: #000000;
line-height: 20px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
margin-bottom: 60px; 


@media screen and (max-width: 700px) {
margin-bottom: 3px;
font-size: 16px;
line-height: 24px;  
}

@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px; 
}
`
const NavBtn = styled.nav`
display: flex;
align-items: center;
background-color:  #0D5FFF;

width: 198px;
height: 48px;
box-sizing: border-box;
border-radius: 4px;

@media screen and (max-width: 700px) {
background:#0D5FFF;
}

@media screen and (max-width: 700px) {
width: 100%;
background: #0D5FFF;
}


&:hover {
  transition: all 0.2s ease-in-out;
  border: 1px solid  #0033FF;
  background: #0033FF;
color: #0033FF;
}

`

const NavBtnLink = styled.div`
margin: 0 auto;
line-height: 24px;
font-size: 14px;
font-weight: 700;
font-family: GT America Regular;
box-sizing: border-box;
outline: none;
cursor: pointer;
color: #fff;
`



export default Careers