import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components";
import Hand from "../assets/hand.png";
import Accordion2 from "./Accordion2"
import CuttingTop1 from "../assets/services-image-5_d.jpg";
import CuttingTop2 from "../assets/services-image-5_m.jpg";
import CuttingMid1 from "../assets/services-image-6_d.jpg";
import CuttingMid2 from "../assets/services-image-6_m.jpg";



const Cutting = ({fluid}) => {

const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "services-image-6_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
  <Div>
    <Div1>
      <Img1 src={CuttingTop1} alt=""/>
    </Div1>
    <Div2>
      <Div3>
        <Title id="cutting">Cutting</Title>

          <ContainImg>
            <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
          </ContainImg>
          <P3>
            We have a proven team of individuals that spearhead our concrete slab sawing and wall sawing operations.
            Our lead hand has achieved a full array of accolades over the 20 plus years of cutting in the industry.
         </P3>
          <P4>
            We offer wall cutting, slab cutting, & floor cutting for industrial, 
            commercial and residential projects.
          </P4>
          {/* <Img3 src={CuttingMid2} alt=""/> */}
          <Img3>
           <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
          </Img3>

      </Div3>

      <Div4>
         <P1>
            We have a proven team of individuals that spearhead our concrete slab sawing and wall sawing operations.
            Our lead hand has achieved a full array of accolades over the 20 plus years of cutting in the industry.
         </P1>
          <P2>
            We offer wall cutting, slab cutting, & floor cutting for industrial, 
            commercial and residential projects.
          </P2>

        <Divider></Divider>
        <Accordion2/>

      </Div4>
    </Div2>
  </Div>
  )
}


const Title2 = styled.h3`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #000000;
padding-bottom: 32px;

@media screen and (max-width: 700px) {
line-height: 40px;
font-size: 24px;
padding-bottom: 19px;

}
`

const Divider = styled.hr`
display: flex;
justify-content: center;
width: 100%;

border: 2px solid #2287B2;
margin-top: 80px;
margin-bottom: 8px;

@media screen and (max-width: 700px) {
margin-top: 40px;
margin-bottom: 8px;
}

@media screen and (max-width: 1212px) {
margin-top: 20px;
margin-bottom: 8px;
}
`

const P1 = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
margin-top: 16px;
color: #000000;

@media screen and (max-width: 1110px) {
font-weight: 400;
line-height: 24px;
font-size: 16px;
margin-top: 0px;

}


@media screen and (max-width: 700px) {
display: none;
}
`
const P3 = styled.p`
display: none;

@media screen and (max-width: 700px) {
display: block;
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
color: #000000;
margin-top: 0px;
margin-left: 16px;
margin-right: 16px;


}
`
const P4 = styled.p`
display: none;

@media screen and (max-width: 700px) {
display: block;
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
color: #000000;
margin-top: 10px;
margin-bottom: 30px;
margin-left: 16px;
margin-right: 16px;


}
`

const P2 = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
margin-top: 16px;
color: #000000;

@media screen and (max-width: 1110px) {
line-height: 24px;
font-size: 16px;
margin-top: 10px;

}

@media screen and (max-width: 700px) {
display: none;

}
`
const ContainImg = styled.div`
width: 80%;
height: 440px;
// background-image: url(${CuttingMid1});
// background-size: 100% 100%;

@media screen and (max-width: 1110px) {
height: 300px;
}
@media screen and (max-width: 700px) {
display: none
}

`

const Img3 = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
height: 300px;
padding-left: 16px;
padding-right: 16px;
width: 100%;

}
`

const Div4 = styled.div`
width: 50%;
height: 100%;
background-color: #fff;
padding-top: 63px;

@media screen and (max-width: 700px) {
width: 100%;
background-color: #fff;
padding-right: 16px;
padding-left: 16px;
padding-top: 0px;

}
@media screen and (max-width: 1212px) {
padding-top: 30px;
}
`

const Div3 = styled.div`
width: 50%;
background-color: #fff;


@media screen and (max-width: 700px) {
width: 100%;

}
`

const Title = styled.h1`
font-weight: 700;
line-height: 80px;
font-size: 80px;
font-family: "GT America Bold";
padding-top: 53px;
padding-bottom: 53px;
color: #000000;

@media screen and (max-width: 1110px) {
font-weight: 700;
line-height: 48px;
font-size: 40px;
padding-top: 20px;
margin-right: 24px;
padding-bottom: 16px;

}

@media screen and (max-width: 700px) {
font-weight: 700;
line-height: 48px;
font-size: 40px;
padding-top: 20px;
margin-left: 16px;
margin-right: 16px;
padding-bottom: 16px;

}

`
const Div2 = styled.div`
margin-right: 64px;
margin-left: 64px;
display: flex;
// height: 712px;
height: 800px;

background-color: #fff;


@media screen and (max-width: 1470px) {
height: 850px;
}

@media screen and (max-width: 781px) {
height: 900px;
}
@media screen and (max-width: 700px) {
margin-right: 0px;
margin-left: 0px;
display: block;
height: 800px;

}
`

const Div1 = styled.div`
width: 100%;
`
const Img1 = styled.img`
// height: 550px;
height: auto;
width: 100%;

// @media screen and (max-width: 1110px) {
// height: 400px;

// }

// @media screen and (max-width: 700px) {
// height: 300px;

// }
`

const Div = styled.div`
background-color: #fff;



@media screen and (max-width: 700px) {
// margin-bottom: 360px;
margin-bottom: 180px;
}

@media screen and (max-width: 555px) {
margin-bottom: 260px;
}

@media screen and (max-width: 382px) {
margin-bottom: 300px;
}


`

export default Cutting
