import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Hand1 from "../assets/home-image-3.jpg";
import Hand2 from "../assets/home-image-4.jpg";
import Hand3 from "../assets/home-image-5.jpg";
import Image from "gatsby-image"


import Accordion from "./Accordion"

export const SliderData = [
    {
        image: Hand1,
        title: "Scanning",
        description: "Our certified scanning technicians have up to 15 years of hands-on experience, and use the most state-of-art GPR equipment."

    },
    {
        image: Hand2,
        title: "Coring",
        description: " We can provide coring services for all sizes of projects from large industrial & commercial to small residential jobs."

    },
    {
        image: Hand3,
        title: "Cutting",
        description: "We offer wall cutting, slab cutting, & floor cutting for industrial, commercial and residential projects."
        
    },
] 

