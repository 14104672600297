
import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Hand from "../assets/hand.png";
import Accordion from "./Accordion";
import Hand1 from "../assets/hand.png";
import Hand2 from "../assets/projects-2.jpg";
import Hand3 from "../assets/projects-1.jpg";
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from "react-icons/fa"
import {AiFillRightSquare, AiFillLeftSquare} from "react-icons/ai"
import {SliderData} from "./SliderData";
import Arrow from "../assets/arow.svg"



const ImageSlider = ({slides}) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

    const nextSlide = () => {
        setCurrent(current === length -1 ? 0 : current + 1) 
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length -1 : current -1)
    }

  return (
    <Slider>
    {SliderData.map((slide, index) => {
        return(
            <div style={{display: "block"}}>
            <div className={index === current ? "slide active" : "slide"}
            key={index}>
                {index === current && (
                <ContIcon>
                     <Img1 src={slide.image} alt=""/>
                     <LeftArrow2>
                        <AiFillRightSquare onClick={nextSlide}/>
                     </LeftArrow2>
                </ContIcon>

                )}
               
            </div>
            <div className={index === current ? "slide active" : "slide"}
            key={index}>
                {index === current && (
                <Title1 >
                    {slide.title}
                </Title1>

                )}
            </div>
             <div className={index === current ? "slide active" : "slide"}
            key={index}>
                {index === current && (
                <P1>
                    {slide.description}
                </P1>

                )}
            </div>
            </div>
        ) 
    })}

    
    </Slider>

  )
}


const P1 = styled.p`
font-weight: 400;
line-height: 16px;
font-size: 12px;
font-family: "GT America Regular";
color: #000000;
// margin-left: 16px;


`

const Title1 = styled.h3`
line-height: 24px;
font-size: 16px;
padding-bottom: 6px;
font-family: "GT America Bold";
color: #000000;
margin-top: 12px;
// margin-left: 16px;

`
const Slider = styled.section`
position: relative;
display: flex;
justify-content: center;
align-items: center;
`

const Img1 = styled.img`
width: 100%;
height: 300px;
// height: auto;

// @media screen and (max-width: 700px) {
// margin-bottom: 900px;
// }

`

const LeftArrow = styled.div`
position: relative;
top: 120px;
font-size: 3rem;
color: #0D5FFF;
z-index: 10;
cursor: pointer;
user-selector: none;
left: 0.3px;
`

const LeftArrow2 = styled.div`
position: relative;
// position: absolute;
top: 120px;
// top: 220px;


font-size: 2.5rem;
// color: #0D5FFF;
// color: #0033FF;
color: #fff;


z-index: 10;
cursor: pointer;
user-selector: none;

// Options for arrow positioning
left: -36px;
// left: -5px;
// left: 20px;

// @media screen and (max-width: 500px) {
// top: 180px;
// }
`

const ContIcon = styled.div`
display: flex;
// padding-left: 16px;
`


export default ImageSlider
