
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Home from "../assets/home-image-2.jpg";
import Hand1 from "../assets/home-image-3.jpg";
import Hand2 from "../assets/home-image-4.jpg";
import Hand3 from "../assets/home-image-5.jpg";

import Accordion from "./Accordion"
import ImageSlider from "./ImageSlider"
import {SliderData} from "./SliderData"
import TestimonialList from "../components/TestimonialList"
// import emailjs from "emailjs-com"


const Form = () => {

// function sendEmail(e) {
//   e.preventDefault();

//     emailjs.sendForm('gmail', 'template-6lisypd', e.target, 'user_HzGuQk1lWy7qBKKi8yOmL')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//       e.target.reset()
// }

  return (
    <>
    <DivTop>
      <DivInner>
        <Container1>
          <InFooterr>

          <BoxText>
            <BoxText1>
               We'd love to hear from you
            </BoxText1>
            <P>Please fill out the form to contact us, and we'll be in touch with you shortly </P> 
          </BoxText>
         

          {/* <ContMap action="https://formspree.io/f/xleanwbk" method="POST">
              <Div>
               <Input placeholder="Name" type="name" name="name">
               </Input>
               <Input2 placeholder="Email Address" type="email" name="email">
               </Input2>
             </Div>
             <Textarea placeholder="Enter your message here" type="message" name="message"></Textarea>
            <div style={{display: "flex", justifyContent: "flex-end"}}> 
                <NavBtn type="submit">
                  <NavBtnLink type="submit">SEND MESSAGE</NavBtnLink>
                </NavBtn>
              <div></div>
            </div>

            
          </ContMap>
           */}

          <form className="contMap" action="https://formspree.io/f/xleanwbk" method="POST">

            <div className="Div">
               <input className="Input" placeholder="Name" type="name" required/>
               <input className="Input2" placeholder="Email Address" type="email" required/>
            </div>
             
           <textarea className="Textarea" placeholder="Enter your message here" type="message" name="message" required></textarea>

          <div style={{display: "flex", justifyContent: "flex-end"}}>
             <button className="NavBtn" type="submit">
                  <div className="NavBtnLink" type="submit">SEND MESSAGE</div>
                </button>
          </div>
            <div></div>
          </form>

         </InFooterr>
        </Container1>
      </DivInner>
    </DivTop>
 
   </>
  )
}




const InFooterr = styled.div`
display: flex;
height: 600px;

@media screen and (max-width: 1110px) {
  display: block;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 0px;
}

@media screen and (max-width: 700px) {
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 0px;
}
`

const ContMap = styled.form`
height: 304px;
width: 70%;
margin-left: 64px;
margin-right: 64px;
margin-top: 96px;

@media screen and (max-width: 1200px) {
width: 60%;

}

@media screen and (max-width: 1110px) {
margin-left: 0px;
margin-right: 0px;
height: 500px;
width: 100%;
display: block;
margin-top: 26px;

}
`

const Div = styled.div`
display: flex;
margin-bottom: 25px;


@media screen and (max-width: 700px) {
display: block;
}

`


const Input = styled.input`
	padding: 0.5em;
	color: #949494;
	background: #fff;
	border: none;
	width: 100%;
  margin-bottom: 0.5em;
  margin-right: 24px;
  height: 48px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  font-family: "GT America Regular"; 

@media screen and (max-width: 1110px) {
margin-bottom: 16px;
width: 100%;
margin-right: 16px;
}
`

const Input2 = styled.input`
	padding: 0.5em;
	color: #949494;
	background: #fff;
	border: none;
	width: 100%;
  margin-bottom: 0.5em;
  height: 48px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  font-family: GT America Regular; 

@media screen and (max-width: 1110px) {
margin-bottom: 16px;
width: 100%;
}
`

const Textarea = styled.textarea`
height: 294px;
width: 100%;
margin-right: 16px;
padding: 0.5rem;
line-height: 24px;
font-size: 16px;
font-weight: 400;
font-family: GT America Regular; 
`


const BoxText = styled.div`
color: #fff;
width: 30%;
margin-right: 20px;
font-family: "GT America Regular"; 
margin-left: 64px;
margin-top: 96px;

@media screen and (max-width: 1200px) {
width: 40%;

}

@media screen and (max-width: 1110px) {
margin-left: 0px;
background: #0D5FFF;
display: block;
width: 90%;


}

`

const BoxText1 = styled.div`
line-height: 48px;
font-size: 40px;
font-weight: 700;
font-family: "GT America Bold";
margin-bottom: 20px;

@media screen and (max-width: 1110px) {
font-size: 24px;
line-height: 32px;
weight: 700;
font-family: GT America Bold;
padding-top: 26px;

}

@media screen and (max-width: 700px) {
font-size: 24px;
line-height: 32px;
weight: 700;
font-family: GT America Bold;
margin-top: 26px;
}
`

const P = styled.div`
line-height: 24px;
font-size: 20px;
font-weight: 400;
font-family: "GT America Regular";

@media screen and (max-width: 700px) {
font-size: 16px;
line-height: 24px;
weight: 400;
font-family: GT America Regular;
}
`


const NavBtn = styled.button`
display: flex;
align-items: center;
border: 1px solid #fff;
background-color:  #0D5FFF;
color: #fff;
margin-top: 54px;
width: 174px;
height: 48px;
box-sizing: border-box;
border-radius: 4px;
justify-content: flex-end;

@media screen and (max-width: 700px) {
background: #0033FF;
border: 1px solid #fff;
background-color:  #0D5FFF;
color: #fff;
width: 100%;

}

&:hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
color: #010606;
}

`

const NavBtnLink = styled.div`
margin: 0 auto;
line-height: 24px;
font-size: 14px;
font-weight: 700;
font-family: GT America Regular;
box-sizing: border-box;
outline: none;
cursor: pointer;


`



const Container1 = styled.div`
height: 100%;
background-color: #0D5FFF;
margin-left: 64px;
margin-right: 64px;

@media screen and (max-width: 1110px) {
margin-left: 24px;
margin-right: 24px;
}

@media screen and (max-width: 700px) {
margin-left: 0px;
margin-right: 0px;

}
`

const DivInner = styled.div`
height: 700px;
width: 100%;
position: absolute;
top: 448px;
margin-bottom: 300px;

@media screen and (max-width: 1110px) {
top: 240px;
}

@media screen and (max-width: 700px) {
top: 255px;
height: 770px;


}
`

const DivTop = styled.div`
height: 550px;
width: 100%;
background-color: #0033ff;

@media screen and (max-width: 1110px) {
height: 636px;

}

@media screen and (max-width: 700px) {
background-color: #0D5FFF;
height: 780px;

}
`
 




export default Form
