import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
// import { Link } from "gatsby"
import styled from "styled-components";
import Top1 from "../assets/services-image-1_d.jpg";
import Top2 from "../assets/services-image-1_m.jpg";
import Scan1 from "../assets/services-image-2_d.jpg";
import Scan2 from "../assets/services-image-2_m.jpg";
import {Link} from "react-scroll";


import Accordion from "./Accordion"

const Scanning = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "services-image-2_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)
  
  return (
  <Div>
     <ScrollBox>
        <Scroll>
          <Row>
            <Link activeClass="active" to="scanning" spy={true} smooth={true} offset={-70} duration={1500}>
                <Column>
                  Scanning
                </Column>
            </Link>

            <Link activeClass="active" to="coring" spy={true} smooth={true} offset={-70} duration={1600}>
                <Column style={{borderRight: "2px solid white", borderLeft: "2px solid white"}}>
                  Coring
                </Column>
            </Link>

            <Link activeClass="active" to="cutting" spy={true} smooth={true} offset={-70} duration={2000}>
                <Column>
                  Cutting
                </Column>
            </Link>
          </Row>
          
        </Scroll>
     </ScrollBox>



    <Div2>
      <Div3>
        <Title id="scanning">Scanning</Title>
        <DivImg>
          <ContImg1>
             <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
          </ContImg1>

        </DivImg>
  
          <P3>
            Our state-of-the-art GPR scanning equipment is completely non-invasive and non-destructive and has the ability to penetrate up to 18" in depth or a total of 36" with access to both sides of a wall or slab, giving the location of any electrical conduits, reinforcing steel, in floor heating or copper piping.
         </P3>
          {/* <Img3 src={Scan2} alt=""/> */}
          <Img3>
            <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
             }}/>
          </Img3>

      </Div3>

      <Div4>
         <P1>
            Our state-of-the-art GPR scanning equipment is completely non-invasive and non-destructive and has the ability to penetrate up to 18" in depth or a total of 36" with access to both sides of a wall or slab, giving the location of any electrical conduits, reinforcing steel, in floor heating or copper piping.
         </P1>
        <Divider></Divider>
        <Accordion/>

      </Div4>
    </Div2>
  </Div>
  )
}

const Row = styled.div`
&:after {
    content: "";
  display: table;
  clear: both;
  }
`

const Column = styled.div`
float: left;
  width: 33.33%;
  padding: 15px;
  height: 64px;
  text-align: center;
  background-color: #2287B2;
  color: white;
  scroll-behavior: smooth;

font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";

&:hover {
  background-color: #0D6DA3;
}

@media screen and (max-width: 1110px) {
height: 56px;
line-height: 24px;
font-size: 16px;
}
`

const ScrollBox = styled.div`
height: 64px;
`

const Scroll = styled.div`
margin-left: 64px;
margin-right: 64px;
height: 64px;


@media screen and (max-width: 1110px) {
margin-left: 0px;
margin-right: 0px;
height: 56px;
}

`


const Title2 = styled.h3`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #000000;
padding-bottom: 32px;

@media screen and (max-width: 700px) {
line-height: 40px;
font-size: 24px;
padding-bottom: 19px;

}
`

const Divider = styled.hr`
display: flex;
justify-content: center;
width: 100%;

border: 2px solid #2287B2;
margin-top: 80px;
margin-bottom: 8px;

@media screen and (max-width: 700px) {
margin-top: 40px;
margin-bottom: 8px;
}

@media screen and (max-width: 1212px) {
margin-top: 20px;
margin-bottom: 8px;
}
`

const P1 = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
margin-top: 16px;
color: #000000;

@media screen and (max-width: 1110px) {
line-height: 16px;
font-size: 12px;
margin-top: 0px;

}

@media screen and (max-width: 700px) {
display: none;

}
`
const P3 = styled.p`
display: none;

@media screen and (max-width: 700px) {
display: block;
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
color: #000000;
margin-top: 0px;
margin-left: 16px;
margin-right: 16px;
margin-bottom: 30px;



}
`
const DivImg = styled.div`

`
const ContImg1 = styled.div`
width: 80%;
height: 440px;
// background-image: url(${Scan1});
// background-size: 100% 100%;

@media screen and (max-width: 1110px) {
height: 300px;
}
@media screen and (max-width: 700px) {
 display: none;

}

`

const Img2 = styled.img`
width: 100%;
height: 440px;
padding-right: 100px;

@media screen and (max-width: 1110px) {
padding-right: 60px;
height: 400px;

}

@media screen and (max-width: 700px) {
display: none;
padding-right: 0px;
height: 300px;
margin-bottom: 0px;
}
`
// const Img3 = styled.img`
const Img3 = styled.div`

display: none;

@media screen and (max-width: 700px) {
display: block;
height: 300px;
padding-left: 16px;
padding-right: 16px;
width: 100%;

}
`

const Div4 = styled.div`
width: 50%;
height: 100%;
background-color: #fff;


padding-top: 63px;

@media screen and (max-width: 700px) {
width: 100%;
background-color: #fff;

padding-right: 16px;
padding-left: 16px;
padding-top: 0px;

}
@media screen and (max-width: 1212px) {
padding-top: 30px;
}
`

const Div3 = styled.div`
width: 50%;
background-color: #fff;


@media screen and (max-width: 700px) {
width: 100%;
}
`

const Title = styled.h1`
font-weight: 700;
line-height: 80px;
font-size: 80px;
font-family: "GT America Bold";
padding-top: 53px;
padding-bottom: 53px;
color: #000000;

@media screen and (max-width: 1110px) {
line-height: 48px;
font-size: 40px;
padding-top: 26px;
padding-bottom: 20px;


}

@media screen and (max-width: 700px) {
font-weight: 700;
line-height: 48px;
font-size: 40px;
padding-top: 20px;
margin-left: 16px;
margin-right: 16px;
padding-bottom: 16px;

}

`
const Div2 = styled.div`
margin-right: 64px;
margin-left: 64px;
display: flex;
// height: 830px;
height: 1120px;
background-color: #fff;

@media screen and (max-width: 1110px) {
height: 700px;

}

@media screen and (max-width: 700px) {
margin-right: 0px;
margin-left: 0px;
display: block;
// height: 600px;
// height: 1600px;

background-color: #fff;
height: 450px;

}

`

const Div1 = styled.div`
width: 100%;
`
const Img1 = styled.img`
height: 550px;
width: 100%;

@media screen and (max-width: 700px) {
height: 300px;

}
`

const Div = styled.div`
background-color: #fff;

@media screen and (max-width: 700px) {
height: 690px;

}
`

export default Scanning
