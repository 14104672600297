import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Hand from "../assets/hand.png";



export const Data = [

    {
        cutting: "Wallsawing",
        cuttingDescription: "We employ circular blades on a track-mounted machine which can be attached to vertical walls, steep inclines, or floors. Typically specified to cut precise dimensional door, vent, and window openings with no overcuts. Straight and bevel cuts are also possible."
    },
     {
        cutting: "Slabsawing",
        cuttingDescription: "Typically used for cutting trenches in asphalt, reinforced concrete slab on grade, and suspended slab opening. For outdoor services we use our fuel-burning saws which are equipped with catalytic converters and exhaust scrubbers, which reduce our carbon dioxide emissions. Our electric saws are fumeless and zero emission, which makes them perfect for our indoor work for restaurants, office spaces, and commercial tenant improvement.",
        cuttingDescription2: "Slab sawing is often used in conjunction with our utility scanning procedures to ensure safety and assess work prior to cutting."

    },
     {
        cutting: "Concrete Breaking & Removal",
        cuttingDescription: "We also offer removal of broken up concrete or rock having the equipment to haul and responsibly dispose of your concrete waste with our own trailers."
    },
]

