import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components";
import {AiFillRightSquare, AiFillLeftSquare} from "react-icons/ai"
import {SliderData2} from "./SliderData2"
import TopImgAbout from "../assets/home-image-1d.jpg"
import MiddleImg from "../assets/home-image-6_d.jpg"
import One from "../assets/1“.png"
import Two from "../assets/2”.png"



const TestimonialList = ({slides, fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "home-image-6_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

    const [current, setCurrent] = useState(0);
    const length = slides.length;


    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

    const nextSlide = () => {
        setCurrent(current === length -1 ? 0 : current + 1) 
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length -1 : current -1)
    }

  
  return (
  <>
    <Cont>
       <Cont1>
          <Cont2></Cont2>

          <Testimonial>
            <Mid>
                <Div1>
                    <Title>From our clients</Title>

                    {SliderData2.map((slide, index) => {
                       return(
                          <div style={{display: "block"}}>

                                <div className={index === current ? "slide active" : "slide"}key={index}>
                                   {index === current && (
                                     <P>
                                     <ImgOne src={One} alt=""/>
                                         {slide.description}
                                     <ImgTwo src={Two} alt=""/>
                                     </P>
                                )}
                               </div>

                               <div className={index === current ? "slide active" : "slide"} key={index}>
                                  {index === current && (
                                    <Name>- {slide.name} </Name>

                                   )}
                               </div>
                         </div>
           
                            ) 
                    })}

               </Div1>

               <LeftArrow>
                     <AiFillRightSquare onClick={nextSlide}/>
               </LeftArrow>

           </Mid>
        </Testimonial>

      <Img3 src={MiddleImg} alt=""/>
       <ContImg3>
          <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
       </ContImg3>

      <Mobile>
          
           <Testimonial2>
            <Mid>
                <Div1>
                    <Title>From our clients</Title>

                    {SliderData2.map((slide, index) => {
                       return(
                          <div style={{display: "block"}}>

                                <div className={index === current ? "slide active" : "slide"}key={index}>
                                   {index === current && (
                                     <P>
                                     <ImgOne src={One} alt=""/>
                                         {slide.description}
                                     <ImgTwo src={Two} alt=""/>
                                     </P>
                                )}
                               </div>

                               <div className={index === current ? "slide active" : "slide"} key={index}>
                                  {index === current && (
                                    <Name>- {slide.name} </Name>

                                   )}
                               </div>
                         </div>
           
                            ) 
                    })}

               </Div1>

               <LeftArrow>
                     <AiFillRightSquare onClick={nextSlide}/>
               </LeftArrow>

           </Mid>
        </Testimonial2>
      </Mobile>

    </Cont1>
  </Cont>

</>


  )
}

const Mobile = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
}
`

const ImgOne = styled.img`
padding-right: 8px;
`
const ImgTwo = styled.img`
padding-left: 8px;

`

const Div1 = styled.div`

`
const Mid = styled.div`
display: flex;
height: 100%;

`

const Name = styled.p`
font-weight: 400;
line-height: 16px;
font-size: 14px;
font-family: "GT America Regular";
color: #fff;
margin-top: 52px;
padding-left: 72px;

@media screen and (max-width: 1110px) {
padding-top: 16px;
padding-left: 36px;
padding-right: 70px;
}

@media screen and (max-width: 700px) {
margin-top: 0px;
padding-top: 20px;
padding-left: 16px;

}

`

const P = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
color: #fff;
padding-top: 28px;
padding-left: 72px;
padding-right: 90px;

@media screen and (max-width: 1110px) {
line-height: 24px;
font-size: 16px;
padding-top: 16px;
padding-left: 36px;
padding-right: 36px;


}

@media screen and (max-width: 700px) {
padding-left: 16px;
padding-right: 16px;
}
`

const Title = styled.h3`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";
color: #fff;
padding-top: 72px;
padding-left: 72px;

@media screen and (max-width: 1110px) {
line-height: 32px;
font-size: 24px;
padding-top: 34px;
padding-left: 36px;
}

@media screen and (max-width: 700px) {
padding-left: 16px;
}

 `
const LeftArrow = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
margin-right: 0px;
font-size: 3rem;
color: #FFF;
z-index: 10;
cursor: pointer;
user-selector: none;
float: right;
`

const Testimonial = styled.div`
display: block;
width: 50%;
height: 464px;
background-color: #2287B2;
position: absolute;
margin-left: 64px;
margin-top: 64px;

@media screen and (max-width: 1110px) {
margin-left: 85px;
height: 380px;
}

@media screen and (max-width: 700px) {
display: none;

}

`

const Testimonial2 = styled.div`
display: none;



@media screen and (max-width: 700px) {
width: 100%;
height: 280px;
background-color: #2287B2;
display: block;

}

`

const Img = styled.img`
width: 100%;

@media screen and (max-width: 700px) {
display: none;
}
`
const Img1 = styled.img`
display: none;

@media screen and (max-width: 700px) {
display: block;
width: 100%;
}
`

const Img3 = styled.img`
width: 70%;

@media screen and (max-width: 1110px) {
width: 50%;
}

@media screen and (max-width: 700px) {
display: none;


}
`

const ContImg3 = styled.div`
  @media screen and (max-width: 700px) {
  display: block;
  // height: 400px;
  height: 350px;
  // width: 100%;
  // background-image: url(${MiddleImg});
  // background-size: 100% 100%;
}
`

const Cont2 = styled.div`
width: 30%;

@media screen and (max-width: 1110px) {
width: 50%;
}

@media screen and (max-width: 700px) {
display: none;
}
`

const Cont1 = styled.div`
background-color: #0D6DA3;
height: 590px;

display: flex;

@media screen and (max-width: 1110px) {
height: 500px;
}

@media screen and (max-width: 700px) {
display: block;
margin-bottom: 0px;
height: 100%;
background-color: #FFF;

}
`

const Cont = styled.div`

@media screen and (max-width: 700px) {
margin-top: 500px;
}

@media screen and (max-width: 665px) {
margin-top: 450px;
}

@media screen and (max-width: 585px) {
margin-top: 400px;
}

@media screen and (max-width: 540px) {
margin-top: 350px;
}
@media screen and (max-width: 500px) {
margin-top: 300px;
}

@media screen and (max-width: 450px) {
margin-top: 250px;
}
@media screen and (max-width: 400px) {
margin-top: 200px;
}

@media screen and (min-width: 1530px) {
margin-top: 280px;
}

@media screen and (min-width: 1800px) {
margin-top: 350px;
}

@media screen and (min-width: 2030px) {
margin-top: 450px;
}
@media screen and (min-width: 2340px) {
margin-top: 650px;
}

@media screen and (min-width: 3038px) {
margin-top: 780px;
}

`


export default TestimonialList
