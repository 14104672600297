import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Hand from "../assets/hand.png";



export const DataScanning = [

    {
        scanning: "Utility Mapping and Locating",
        scanningDescription: "With GPR we can determine exact locations of utilities such as water, sewer, gas, telephone & data and fibre optics. These can be accurately located at great depths in optimal soil conditions.",
        scanningDescription2: "Typical applications:",
        scanningDescription3: "* Geotechnical Studies",
        scanningDescription4: "* Environmental studies",
        scanningDescription5: "* Construction sites",
        scanningDescription6: "* Road & civil works",


        im1ga: "../assets/hand.png"
    },
    {
        scanning: "Ground Penetrating Radar",
        scanningDescription: "Using the most advanced GPR technology we can identify the locations of any objects or utilities embedded in concrete in order to facilitate conflict-free construction and renovation.",
        scanningDescription7: "We can detect:",
        scanningDescription8: "* Steel reinforcement (rebar) including its spacing and depth",
        scanningDescription9: "* Post-tensioned cables",
        scanningDescription10: "* Water & radiant heating lines",
        scanningDescription11: "* Electrical conduits (plastic & metal)",
        scanningDescription12: "* Walls, slab bands, and columns",
        scanningDescription13: "* Voids within or below the slab",
        scanningDescription14: "* Almost any kind of object embedded in concrete",

        imag: "../assets/hand.png"
        
    },
    {
        scanning: "Void and Crack Detection",
        scanningDescription: "Cracks are commonly found in large concrete slabs, below roadways and bridges, and within block wall. Voids are caused by substandard concrete construction, defective utilities, poor compaction of the sub grade, poor drainage of the sub grade and ground disturbance. By detecting these flaws we can help to avoid and prevent further damage to concrete structures.",

        imag: "../assets/hand.png"

    },
    {
        scanning: "Pre-design Scanning",
        scanningDescription: "Prior to the interior design phase, we use GPR concrete scanning to inspect the space. A detailed AutoCAD drawing is created, including the locations of all the subsurface infrastructure. These can be utilized by engineers and architects to create design drawings with zero conflict, ultimately saving projects from costly mistakes.",
        imag: "../assets/hand.png"

    },
    {
        scanning: "Surveying and Reporting",
        scanningDescription: "We provide on-site real-time reports of the subsurface using chalk, crayons, and paint, depending on whether the floor is finished and needs to be protected. We save the scan data to be processed and compiled in reports ranging from short explanatory summaries, to full digital reports.",
        scanningDescription15: "We use RADAN7 to process our data, enabling us to show slices of particular scans, or provide 3D analysis of your survey location.",

        imag: "../assets/hand.png"

    },
]

