import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components";
import Home from "../assets/home-image-2.jpg";
import Hand1 from "../assets/home-image-3.jpg";
import Hand2 from "../assets/home-image-4.jpg";
import Hand3 from "../assets/home-image-5.jpg";

import Accordion from "./Accordion"
import ImageSlider from "./ImageSlider"
import {SliderData} from "./SliderData"
import TestimonialList from "../components/TestimonialList";
import Hero from "../components/Hero";



const Carousal = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "home-image-3.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
    <>
    <Hero/>
    
   <Box>
     <BoxColor>

    <Title>What we do</Title>

   <BoxInner>
       <Box1>
         <ContImg>
             <Img1 src={Hand1} alt=""/>
         </ContImg>
          <Title1>Scanning</Title1>
          <P1>
            Our certified scanning technicians have up to 15 years of hands-on experience, and use 
            the most state-of-art GPR equipment.
         </P1>
       </Box1>

       <Box2>
         <ContImg>
            <Img1 src={Hand2} alt=""/>
         </ContImg>
          <Title2>Coring</Title2>
          <P2>
            We can provide coring services for all sizes of projects from large industrial 
            & commercial to small residential jobs.
         </P2>
         <Link to={"/services/"} style={{textDecoration: "none", color: "white"}}>
           <Button>MORE SERVICES</Button>
          </Link>
       </Box2>

       <Box3>
         <ContImg>
           <Img1 src={Hand3} alt=""/>
         </ContImg>
          <Title3>Cutting</Title3>
          <P3>
            We offer wall cutting, slab cutting, & floor cutting for industrial, 
            commercial and residential projects.
         </P3>
       </Box3>

   </BoxInner>

   <Div>
      <ImageSlider slides={SliderData}/>

            <Link to={"/services/"} style={{textDecoration: "none", color: "white"}}>
              <Btn>
                 <BtnLink>MORE SERVICES</BtnLink>
              </Btn>
           </Link>
           
   </Div>
    </BoxColor>

  </Box> 

  <TestimonialList slides={SliderData}/>
   </>
  )
}



const P4 = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
color: #fff;
margin-left: 111px;
margin-right: 111px;
`

const Name = styled.p`
font-weight: 400;
line-height: 16px;
font-size: 14px;
font-family: "GT America Regular";
color: #fff;
margin-left: 111px;
margin-top: 52px;
`
const Title4 = styled.h3`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";
color: #fff;
padding-bottom: 30px;
margin-left: 111px;
`
const Cont1 = styled.div`
height: 61px;
width: 100%;
`
const Cont = styled.div`
width: 644px;
height: 464px;
background-color: #0D5FFF;
margin-left: 64px;

`

const Container = styled.div`
background: linear-gradient(90deg, #0033FF 27%, rgba(0, 0, 0, 0) 27%), url(${Hand1});
height: 586px;

`

const Btn = styled.div`
display: flex;
align-items: center;
border: 1px solid #fff;
background-color: #2287B2;
color: #fff;
margin-top: 26px;
width: 100%;
height: 48px;
box-sizing: border-box;
border-radius: 4px;

margin-bottom: 50px;

`

const BtnLink = styled.div`
margin: 0 auto;
line-height: 24px;
font-size: 14px;
font-weight: 700;
font-family: "GT America Regular";
box-sizing: border-box;
outline: none;
cursor: pointer;

`

const Button = styled.button`
background-color: #2287B2;
padding: 15px 24px; 
color: #FFF;
border-radius: 4px;
border: 1px solid #2287B2;
display: flex;
margin: 53px auto 41px auto;
font-weight: 700;
line-height: 16px;
font-size: 14px;
font-family: "GT America Regular";
`


const Div = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
margin-left: 16px;
margin-right: 16px;
margin-top: 10px;

}

`

const Divi = styled.div`
margin-left: 64px;
margin-right: 64px;

@media screen and (max-width: 700px) {
margin-left: 16px;
margin-right: 16px;

}
`
const Divider = styled.hr`
width: 100%;
border: 8px solid #0D5FFF;
margin-top: 80px;
margin-bottom: 8px;

@media screen and (max-width: 700px) {
margin-top: 40px;
margin-bottom: 8px;
}

`

const P1 = styled.p`
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
color: #000000;
text-align: center;


@media screen and (max-width: 700px) {
display: none;
font-weight: 400;
line-height: 16px;
font-size: 12px;

}
`
const P2 = styled.p`
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
color: #000000;
text-align: center;

@media screen and (max-width: 700px) {
display: none;
font-weight: 400;
line-height: 16px;
font-size: 12px;

}
`
const P3 = styled.p`
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
color: #000000;
text-align: center;


@media screen and (max-width: 700px) {
display: none;
font-weight: 400;
line-height: 16px;
font-size: 12px;

}
`
const Title = styled.h3`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";
color: #fff;
padding-bottom: 30px;
padding-top: 20px;
text-align: center;

@media screen and (max-width: 1110px) {
line-height: 32px;
font-size: 24px;
padding-bottom: 16px;


}

@media screen and (max-width: 700px) {
line-height: 32px;
font-size: 24px;
padding-bottom: 16px;
text-align: left;
margin-left: 16px;
// margin-left: 20px;
margin-right: 16px;
padding-top: 30px;

}
`
const Title1 = styled.h3`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #000000;
padding-bottom: 8px;
text-align: center;

@media screen and (max-width: 700px) {
line-height: 24px;
font-size: 26px;
padding-bottom: 6px;
}
`
const Title2 = styled.h3`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #000000;
padding-bottom: 8px;
text-align: center;


@media screen and (max-width: 700px) {
line-height: 24px;
font-size: 26px;
padding-bottom: 6px;
}
`
const Title3 = styled.h3`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #000000;
padding-bottom: 8px;
text-align: center;


@media screen and (max-width: 700px) {
line-height: 24px;
font-size: 26px;
padding-bottom: 6px;
}
`
const ContImg = styled.div`

`
const Img1 = styled.img`
width: 100%;
height: 100%;
margin-bottom: 16px;

`
const Img2 = styled.img`
width: 100%;
height: 427px;
margin-bottom: 16px;

`
const Img3 = styled.img`
width: 100%;
height: 427px;
margin-bottom: 16px;

`
const Box1 = styled.div`
height: 540px;

`
const Box2 = styled.div`
height: 540px;
margin-left: 16px;
margin-right: 16px;
`
const Box3 = styled.div`
height: 540px;
`

const BoxInner = styled.div`
display: flex;
margin-left: 64px;
margin-right: 64px;

@media screen and (max-width: 700px) {
display: none;
}
`

const Box = styled.div`
height: 600px;
// margin-top: 200px;
margin-bottom: 200px;


@media screen and (max-width: 1330px) {
height: 550px;
}

@media screen and (max-width: 1110px) {
height: 450px;
}
@media screen and (max-width: 1000px) {
height: 430px;
}
@media screen and (max-width: 885px) {
height: 400px;
}
@media screen and (max-width: 885px) {
height: 450px;

}
@media screen and (max-width: 400px) {
margin-bottom: 380px;
}

@media screen and (max-width: 700px) {
// margin-top: 350px;
margin-top: 250px;
// height: 430px;
// height: 880px;


margin-bottom: 120px;
}
// @media screen and (max-width: 615px) {
// height: 800px;

// }
// @media screen and (max-width: 580px) {
// height: 750px;
// }
// @media screen and (max-width: 550px) {
// height: 700px;
// }
// @media screen and (max-width: 490px) {
// height: 650px;
// }
// @media screen and (max-width: 475px) {
// height: 610px;
// }
// @media screen and (max-width: 430px) {
// height: 590px;
// }
// @media screen and (max-width: 430px) {
// height: 550px;
// }
// @media screen and (max-width: 360px) {
// height: 510px;
// }
@media screen and (max-width: 485px) {
margin-top: 280px;
}
`
const BoxColor = styled.div`
height: 100%;
background: linear-gradient(#0D6DA3 50%, #FFF 50%);

@media screen and (max-width: 700px) {
background: linear-gradient( #2287B2 50%, #FFF 50%);
// background: linear-gradient( #0D5FFF 60%, #FFF 40%);


}
`



export default Carousal
