import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components";
import Coring1 from "../assets/services-image-3_d.jpg"
import Coring2 from "../assets/services-image-3_m.jpg"
import Cor1 from "../assets/services-image-4_d.jpg"
import Cor2 from "../assets/services-image-4_m.jpg"




const Coring = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "services-image-4_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
  <Div >
    <Div1>
      <Img1 src={Coring1} alt=""/>
      {/* <Img1>
      <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
      </Img1> */}


    </Div1>
    <Div2>
      <Div3>
        <Title id="coring">Coring</Title>

     <ContainImg>
        <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
    </ContainImg>
      {/* <Img3 src={Cor2} alt=""/> */}
      <Img3>
      <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>

      </Img3>


      </Div3>
      <Div4>
        <P1>
          Our core drilling services allow us to core from ¾" up to 20". 
          We can provide coring services for all sizes of projects from large industrial & 
          commercial to small residential jobs.
        </P1>
        <Divider></Divider>
        <Title2>Concrete Coring</Title2>

        <Cont>
          <Cont1>
            <Title3>Types of coring we offer</Title3>
            <List1>
                    <Ul>
                      <Li>
                          Conventional coring
                      </Li>
                      <Li>
                          Upside-down coring
                      </Li>
                      <Li>
                          Confined spaces
                      </Li>
                      <Li>
                          At heights
                      </Li>
                      <Li>
                          Angled holes
                      </Li>
                   </Ul>
                </List1>

          </Cont1>
          <Cont2>
            <Title4>Typical applications</Title4>
            <List1>
                    <Ul>
                      <Li>
                          Mechanical and electrical penetrations
                      </Li>
                      <Li>
                          Structural testing
                      </Li>
                      <Li>
                          Anchoring and reinforcement
                      </Li>
                      <Li>
                          Sewer tie-ins
                      </Li>
                      <Li>
                          Manholes
                      </Li>
                      <Li>
                          Drainage
                      </Li>
                      <Li>
                          Telecommunication lines
                      </Li>
                      <Li>
                          Concrete Sample Analysis
                      </Li>
                      <Li>
                          Signage
                      </Li>
                   </Ul>
                </List1>
          </Cont2>
        </Cont>
      </Div4>
    </Div2>
  </Div>
  )
}


const ContainImg = styled.div`
width: 80%;
height: 440px;
// background-image: url(${Cor1});
// background-size: 100% 100%;

@media screen and (max-width: 1110px) {
height: 300px;
}
@media screen and (max-width: 700px) {
display: none
}

`

const Img3 = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
height: 300px;
padding-left: 16px;
padding-right: 16px;
width: 100%;

}
`

const ContImg = styled.div`
width: 100%;
  height: 440px;
  background-image: url(${Cor1});
  background-size: 100% 100%;

  @media screen and (max-width: 1110px) {
  background-image: url(${Cor1});
  background-size: 100% 100%;
  height: 400px;
}

  @media screen and (max-width: 700px) {
  height: 350px;
  background-image: url(${Cor2});
}
`
const Ul = styled.ul`
color: #fff;
font-family: "GT America Regular";
font-weight: 400;
font-size: 16px;
line-height: 24px;

@media screen and (max-width: 700px) {
line-height: 24px;
font-size: 16px;

}
`
const List1 = styled.div`
padding-left: 17px;

`

const Li = styled.li`

`

const Title3 = styled.h5`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #fff;
padding-bottom: 8px;


@media screen and (max-width: 700px) {
line-height: 24px;
font-size: 16px;
padding-bottom: 19px;

}
`
const Title4 = styled.h5`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #fff;
padding-bottom: 8px;

@media screen and (max-width: 700px) {
line-height: 24px;
font-size: 16px;
padding-bottom: 19px;

}

@media screen and (max-width: 1110px) {
padding-bottom: 19px;
padding-top: 19px;


}
`
const Cont1 = styled.div`
padding-right: 100px;
`
const Cont2 = styled.div`
background-color: #2287B2;
`
const Cont = styled.div`
background-color: #2287B2;
display: flex;

@media screen and (max-width: 1110px) {
display: block;
}

`

const Title2 = styled.h3`
font-weight: 700;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
color: #fff;
padding-bottom: 32px;

@media screen and (max-width: 1110px) {
line-height: 40px;
font-size: 24px;
padding-bottom: 19px;

}

@media screen and (max-width: 700px) {
line-height: 40px;
font-size: 24px;
padding-bottom: 19px;

}
`

const Divider = styled.hr`
display: flex;
justify-content: center;
width: 100%;
border: 2px solid #949494;
margin-top: 80px;
margin-bottom: 8px;

@media screen and (max-width: 1110px) {
margin-top: 40px;
margin-bottom: 8px;
}

@media screen and (max-width: 700px) {
margin-top: 40px;
margin-bottom: 8px;
}

`

const P1 = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
margin-top: 16px;
color: #fff;

@media screen and (max-width: 1110px) {
line-height: 24px;
font-size: 16px;
margin-top: 44px;

}

@media screen and (max-width: 700px) {
font-weight: 400;
line-height: 24px;
font-size: 16px;
margin-top: 0px;

}
`

const Img2 = styled.img`
width: 100%;
height: 440px;
padding-right: 100px;

@media screen and (max-width: 700px) {
padding-right: 0px;
height: 300px;
margin-bottom: 0px;
}
`

const Div4 = styled.div`
width: 50%;
height: 100%;
background-color: #2287B2;

padding-top: 63px;

@media screen and (max-width: 700px) {
width: 100%;
background-color: #2287B2;
padding-right: 16px;
padding-left: 16px;

padding-top: 0px;

}
@media screen and (max-width: 1212px) {
padding-top: 30px;
}
`

const Div3 = styled.div`
width: 50%;

@media screen and (max-width: 700px) {
width: 100%;
}
`

const Title = styled.h1`
font-weight: 700;
line-height: 80px;
font-size: 80px;
font-family: "GT America Bold";
padding-top: 53px;
padding-bottom: 53px;
color: #fff;

@media screen and (max-width: 1110px) {
font-weight: 700;
line-height: 48px;
font-size: 40px;
padding-top: 41px;
padding-bottom: 30px;

}

@media screen and (max-width: 700px) {
font-weight: 700;
line-height: 48px;
font-size: 40px;
padding-top: 20px;
padding-left: 16px;
}

`
const Div2 = styled.div`
margin-right: 64px;
margin-left: 64px;
display: flex;
height: 712px;
background-color: #2287B2;



@media screen and (max-width: 700px) {
margin-right: 0px;
margin-left: 0px;

display: block;
height: 620px;
background-color: #2287B2;



}
@media screen and (max-width: 1116px) {
height: 800px;

}

@media screen and (max-width: 728px) {
height: 850px;

}

@media screen and (max-width: 700px) {
height: 710px;

}

@media screen and (max-width: 383px) {
height: 750px;

}
`

const Div1 = styled.div`
width: 100%;
`
const Img1 = styled.img`
width: 100%;
height: auto;

// height: 550px;
// width: 100%;

// @media screen and (max-width: 1110px) {
// height: 400px;
// }

// @media screen and (max-width: 700px) {
// height: 300px;
// }
`

const Div = styled.div`
background-color: #2287B2;

@media screen and (max-width: 700px) {
margin-top: 510px;
margin-bottom: 390px;

}

@media screen and (max-width: 500px) {
margin-top: 530px;
}

@media screen and (max-width: 425px) {
margin-top: 600px;
}

`

export default Coring
