import React, {useState, useEffect} from "react"
import SocialLinks from "../constants/socialLinks"
import styled from "styled-components"
import BottomImg from "../assets/about-image-5_d.jpg"
import BottomImg2 from "../assets/about-image-5_m.jpg"
import { Link } from "gatsby"
import Careers from "../components/Careers"

const Footer = () => {

  const [date , setDate] = useState();

    const getYear = () =>  setDate(new Date().getFullYear())


    useEffect(() => {
        getYear();
    }, [])
  // allowfullscreen="" loading="lazy"
  
  return (
    <>
     
    <OutFooter>

      <InFooter>

        <InFooterr>
          <ContMap>
            <iframe 
             // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.880483509089!2d-122.98035048398057!3d49.202826779322756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867622be138539%3A0x7175807c35534fcd!2s5850%20Byrne%20Rd%2C%20Burnaby%2C%20BC!5e0!3m2!1sen!2sca!4v1621633931468!5m2!1sen!2sca"
             src="https://www.google.com/maps/embed/v1/place?q=5148+Still+Creek+Avenue,+Burnaby,+BC+V5C+4E4,+Canada&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
             style={{width: "100%", height: "100%", border: "0" }} allowfullscreen="" >
             </iframe>
          </ContMap>

          <BoxText>

            <BoxText1>
               Get in touch
            </BoxText1>

            <P>5148 Still Creek Avenue, </P> 
            <P style={{marginBottom: "25px"}}>Burnaby, BC V5C 4E4</P> 

            <P style={{fontFamily: "GT America Bold"}}>Mitchel Maguire</P> 
            <P>President/Owner</P> 
            <P>604-765-1893</P> 
            <P style={{marginBottom: "25px"}}>
              <A href="mailto:mitch@scancore.ca" style={{color: "#FFF"}}>mitch@scancore.ca</A>
            </P>

            <P style={{fontFamily: "GT America Bold"}}>Myles Rideout</P> 
            <P>Operations and Dispatch Manager</P> 
            <P>778-990-9583</P> 
            <P style={{marginBottom: "25px"}}>
              <A href="mailto:myles@scancore.ca" style={{color: "#FFF"}}>myles@scancore.ca</A>
            </P>

            <P style={{fontFamily: "GT America Bold"}}>Office Admin and Accounts Receivable/Payable</P>
            <P2 style={{color: "white"}}>604-879-2425 </P2>
            <P2 style={{marginBottom: "28px"}}>
              <A href="mailto:scancore@telus.net" style={{color: "#FFF"}}>scancore@telus.net</A>
            </P2>

            {/* <NavBtn>
               <NavBtnLink>GET IN TOUCH</NavBtnLink>
            </NavBtn> */}

             <P3>
                &copy; {date} Scancore LTD
            </P3>

          </BoxText>

          

        </InFooterr>

        
        
      </InFooter>

      <DateBox>
       &copy; {date} Scancore LTD
      </DateBox>

      

    </OutFooter>
    
</>
  )
}


const A = styled.a`
`

const OutFooter = styled.div`
background: #0D6DA3;
height: 496px;

@media screen and (max-width: 700px) {
height: 432px;

}

`

const InFooter = styled.div`
background: #2287B2;
height: 432px;
margin-left: 64px;
margin-right: 64px;
display: flex;
align-items: center;
justify-content: center;
@media screen and (max-width: 1110px) {
margin-left: 24px;
margin-right: 24px;
background: #2287B2;
}

@media screen and (max-width: 700px) {
display: block;
margin-left: 0px;
margin-right: 0px;
// background: #2287B2;

}

`

const InFooterr = styled.div`
display: flex;
@media screen and (max-width: 700px) {
flex-direction: column;

}
`

const ContMap = styled.div`
background: #fff;
height: 304px;
width: 533px;
margin-right: 138px;

@media screen and (max-width: 1110px) {
margin-right: 47px;
margin-left: 30px;
margin-right: 30px;
height: 276px;
width: 350px;

}

@media screen and (max-width: 700px) {
margin-left: 0px;
margin-right: 0px;
height: 314px;
width: 100%;
border-top: 4px solid white;
display: none;
}
`
const ContMap2 = styled.div`
background: #fff;
height: 304px;
width: 533px;
margin-right: 138px;
display: none;

@media screen and (max-width: 1110px) {
margin-right: 47px;
margin-left: 30px;
margin-right: 30px;
height: 276px;
width: 350px;

}

@media screen and (max-width: 700px) {
margin-left: 0px;
margin-right: 0px;
height: 314px;
width: 100%;
border-top: 4px solid white;
display: block;

}
`

const BoxText = styled.div`
color: #fff;
width: 270px;
height: 304px;

@media screen and (max-width: 700px) {
// height: 370px;
height: 410px;


width: 100%;
background: #2287B2;
padding-left: 16px;
padding-right: 16px;

}

`

const BoxText1 = styled.h4`
line-height: 32px;
font-size: 24px;
font-weight: 700;
font-family: "GT America Bold";
margin-bottom: 25px;

@media screen and (max-width: 1100px) {
margin-bottom: 16px;
}

@media screen and (max-width: 700px) {
margin-bottom: 25px;
margin-top: 25px;

}
`

const P = styled.div`
line-height: 16px;
font-size: 14px;
font-weight: 400;
font-family: "GT America Regular";


`

const P2 = styled.div`
line-height: 16px;
font-size: 14px;
font-weight: 400;
font-family: "GT America Regular";
`

const P3 = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
line-height: 16px;
font-size: 14px;
font-weight: 400;
font-family: "GT America Regular";
}
`

const NavBtn = styled.nav`
display: flex;
align-items: center;
border: 1px solid #fff;
background-color:  #2287B2;
width: 174px;
height: 48px;
box-sizing: border-box;
border-radius: 4px;

@media screen and (max-width: 1110px) {
width: 170px;
height: 48px;   
background: #2287B2;
}

@media screen and (max-width: 700px) {
width: 100%;
background: #2287B2;
}

&:hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
color: #010606;
}

`

const NavBtnLink = styled.div`
margin: 0 auto;
line-height: 24px;
font-size: 14px;
font-weight: 700;
font-family: GT America Regular;
box-sizing: border-box;
outline: none;
cursor: pointer;

`

const DateBox = styled.div`
padding-left: 64px;
padding-top: 16px;
padding-bottom: 16px;
font-size: 12px;
line-height: 16px;
weight: 400;
font-family: "GT America Regular";
background: #0D6DA3;
color: #fff;


@media screen and (max-width: 1110px) {
padding-left: 24px;
}

@media screen and (max-width: 700px) {
// padding-left: 16px;
display: none;
}

@media screen and (max-width: 414px) {
// margin-top: 220px;
}

`

export default Footer
