import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Home from "../assets/home-image-2.jpg";
import Hand1 from "../assets/home-image-3.jpg";
import Hand2 from "../assets/home-image-4.jpg";
import Hand3 from "../assets/home-image-5.jpg";
import Image from "gatsby-image"

import Accordion from "./Accordion"
import ImageSlider from "./ImageSlider"
import {SliderData} from "./SliderData"
import TestimonialList from "../components/TestimonialList";


const Hero = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "home-image-2.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
    <>
    <DivTop>
      <DivInner>
        <Container1>
          <Content>Providing services for all your concrete scanning, cutting & coring needs </Content>
           <MobileImg>
             <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
                width: "100%",
                height: "100%"
                }}/>
            </MobileImg>
           <ContBtn>

             <Link to={"/about/"} style={{textDecoration: "none", color: "white"}}>
                <Btn3>
                   <BtnLink3>ABOUT US</BtnLink3>
                 </Btn3>
              </Link>

           </ContBtn>

           <Link to={"/about/"} style={{textDecoration: "none", color: "white"}}>
              <Btn2>
                 <BtnLink2>ABOUT US</BtnLink2>
              </Btn2>
           </Link>
           
        </Container1>
         
        <Container2>
          <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
        </Container2>

      </DivInner>
    </DivTop>
 
   </>
  )
}

const Container2 = styled.div`
width: 50%;
height: 100%;
margin-right: 64px;

@media screen and (max-width: 1110px) {
margin-right: 24px;
}

@media screen and (max-width: 700px) {
display: none;

}
`

const MobileImg = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
height: 250px;
margin-left: 16px;
margin-right: 16px;
}

`

const Btn3 = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: flex;
align-items: center;
border: 1px solid #fff;
background-color: #2287B2;
color: #fff;
margin-top: 26px;
width: 100%;
height: 48px;
box-sizing: border-box;
border-radius: 4px;

}

`
const ContBtn = styled.div`
margin-left: 16px;
margin-right: 16px;
`

const BtnLink3 = styled.div`
margin: 0 auto;
line-height: 24px;
font-size: 14px;
font-weight: 700;
font-family: "GT America Regular";
box-sizing: border-box;
outline: none;
cursor: pointer;

`

const Btn2 = styled.div`
display: flex;
align-items: center;
border: 1px solid #fff;
background-color:  #2287B2;
color: #fff;
margin-top: 44px;
width: 200px;
height: 48px;
box-sizing: border-box;
border-radius: 4px;
margin-left: 90px;

@media screen and (max-width: 1160px) {
margin-left: 30px;
}

@media screen and (max-width: 1110px) {
margin-left: 32px;
margin-top: 25px;
height: 34px;

}

@media screen and (max-width: 700px) {
display: none;

}

`

const BtnLink2 = styled.div`
margin: 0 auto;
line-height: 24px;
font-size: 14px;
font-weight: 700;
font-family: "GT America Regular";
box-sizing: border-box;
outline: none;
cursor: pointer;

`

const Content = styled.h3`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";
color: #fff;
padding-top: 56px;
padding-left: 90px;
padding-right: 90px;

@media screen and (max-width: 1160px) {
padding-left: 30px;
padding-right: 30px;

}

@media screen and (max-width: 1110px) {
line-height: 32px;
font-size: 24px;
padding-top: 30px;
padding-left: 32px;
padding-right: 32px;

}

@media screen and (max-width: 700px) {
line-height: 32px;
font-size: 24px;
padding-bottom: 16px;
text-align: left;
padding-left: 16px;
padding-right: 16px;
padding-top: 16px;

}
`
const Container1 = styled.div`
width: 50%;
height: 100%;
background-color: #2287B2;
margin-left: 64px;

@media screen and (max-width: 1110px) {
margin-left: 24px;

}

@media screen and (max-width: 700px) {
width: 100%;
margin-left: 0px;
height: 460px;

}

@media screen and (max-width: 495px) {
// height: 490px;
height: 485px;


}

`


const DivInner = styled.div`
height: 424px;
width: 100%;
position: absolute;
top: 448px;
display: flex;

@media screen and (max-width: 1110px) {
top: 320px;
height: 232px;

}
`

const DivTop = styled.div`
height: 300px;
width: 100%;
// background-color: #0033ff;
background-color: #0D6DA3;


@media screen and (max-width: 1110px) {
height: 180px;



}

@media screen and (max-width: 700px) {
height: 100px;
}
`
 




export default Hero
