import React, {useState} from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import {DataScanning} from "./DataScanning"
import {IconContext} from "react-icons";
import {FiPlus, FiMinus} from "react-icons/fi";
import Hand from "../assets/hand.png";


const Accordion = () => {
    const [clicked, setClicked] = useState(false)

    const toggle = index => {
        if(clicked === index) {

            return setClicked(null)
        }
        setClicked(index)
    }


  return (
    <IconContext.Provider value={{color: "#000000", size: "25px"}}>
        <AccordionSection>
            <Container>
                {DataScanning.map((item, index) => {
                    return (
                        <>
                        <Wrap onClick={() => toggle(index)} key={index}>
                            <P1>
                              {item.scanning}
                           

                            <span style={{float: "right"}}>{clicked === index ? <FiMinus/> : <FiPlus />}</span>
                            </P1>


                        </Wrap>

                        {clicked === index ? (
                        <Dropdown>
                           <P2>
                              {item.scanningDescription}

                              <br></br>
                              <br></br>
                              {item.scanningDescription7}
                              {item.scanningDescription15}
                              {item.scanningDescription2}
                              {/* <br></br> */}
                              <br></br>
                              {item.scanningDescription3}
                              {item.scanningDescription8}

                              <br></br>
                              {item.scanningDescription4}
                              {item.scanningDescription9}

                              <br></br>
                              {item.scanningDescription5}
                              {item.scanningDescription10}
                              <br></br>
                              {item.scanningDescription6}
                              {item.scanningDescription11}
                              <br></br>
                              {item.scanningDescription12}
                              <br></br>
                              {item.scanningDescription13}
                              <br></br>
                              {item.scanningDescription14}

                           </P2>
                           {/* <P2>
                              {item.scanningTitle}
                           </P2> */}
                          
                        </Dropdown>

                        ) : null}

                        
                        <Divider></Divider>
                        </>
                    )
                })}
            </Container>
        </AccordionSection>
    </IconContext.Provider>
  );
}

const Ul = styled.ul`
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
margin-top: 8px;

@media screen and (max-width: 1110px) {
line-height: 16px;
font-size: 12px;

}

@media screen and (max-width: 700px) {
font-weight: 400;
line-height: 16px;
font-size: 12px;

}
`

const Li = styled.li`
margin-left: 30px;
`

const Img1 = styled.img`
display: none;


@media screen and (max-width: 700px) {
display: block;
height: 300px;
margin-bottom: 0px;
}
`

const Dropdown = styled.div`

`
const P1 = styled.h4`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Bold";
margin-top: 16px;

@media screen and (max-width: 1110px) {
line-height: 24px;
font-size: 16px;

}

@media screen and (max-width: 700px) {
font-weight: 400;
line-height: 24px;
font-size: 16px;


}
`
const P2 = styled.p`
font-weight: 400;
line-height: 24px;
font-size: 16px;
font-family: "GT America Regular";
margin-top: 16px;

@media screen and (max-width: 1110px) {
line-height: 16px;
font-size: 12px;

}

@media screen and (max-width: 700px) {
font-weight: 400;
line-height: 16px;
font-size: 12px;

}
`
const AccordionSection = styled.div`

`
const Container = styled.div`

`

const Wrap = styled.div`
`

const Divider = styled.hr`
display: flex;
justify-content: center;
width: 100%;

border: 2px solid #2287B2;
margin-top: 16px;

margin-bottom: 8px;

@media screen and (max-width: 700px) {
margin-top: 40px;
margin-bottom: 8px;
}

@media screen and (max-width: 1212px) {
margin-top: 20px;
margin-bottom: 8px;
}
`
export default Accordion
