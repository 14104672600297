// import React from 'react';
// import Team from "../assets/Teamcareers.svg"




// export default function Cards() {

//   return (
//       <div style={{marginTop: "80px", marginLeft: "64px", marginRight: "64px", display: "flex", justifyContent: "center"}}>

//           <div style={{width: "427px", height: "427px", backgroundColor: "green"}}>
//           </div>
//           <div style={{width: "427px", height: "427px", backgroundColor: "red", marginLeft: "14px", marginRight: "14px" }}>
//           </div>
//           <div style={{width: "427px", height: "427px", backgroundColor: "blue" }}>
//           </div>

          
//       </div>
    
//   );
// }

import React from "react";
import ReactDOM from "react-dom";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import One from "../assets/one.svg"
import Two from "../assets/two.svg"
import Three from "../assets/three.svg"


// import Item from "./Item";
// import "./styles.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
//   { width: 1200, itemsToShow: 4 },
];

function Cards() {
  return (
    <>
      <CarBox>
        <Carousel breakPoints={breakPoints}>
          <Item >
              <img src={One} alt="" style={{width: "100%"}}/>
          </Item>
          <Item>
               <img src={Two} alt=""/>
          </Item>
          <Item>
               <img src={Three} alt=""/>
          </Item>
        </Carousel>
      </CarBox>
    </>
  );
}


const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 427px;
  max-width: 427px;
//   width: 100%;
//   background-color: #0D5FFF;
  color: #fff;
//   margin: 0 15px;
// margin-top: 100px;
margin-left: 20px;

  font-size: 4em;

  @media screen and (max-width: 1300px) {
 display: block;

}
`

const CarBox = styled.div`
font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export default Cards