import React from "react";
import styled from "styled-components";
import Hand from "../assets/hand.png"
import TopImg from "../assets/contact-image-1_d.jpg"
import TopImg2 from "../assets/contact-image-1_m.jpg"
import MiddleImg from "../assets/about-image-3_d.jpg"
import MiddleImg2 from "../assets/about-image-3_m.jpg"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



function Experience({fluid}) {

const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "about-image-3_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
    <>
    <Container>
        <Div1>
          <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
        </Div1>
        <Div2>
             <Div3>
                 <Text1>
                    <Title>Our Experience</Title>
                    <P1>
                        Since 2014 we have been offering industry-leading practices, 
                        with the latest GPR and diamond cutting methods, and the most advanced equipment. 
                        Our team is made up of experienced professionals with over 20 years of history in concrete, 
                        and 40+ years in general contracting.
                    </P1>
                </Text1>

                <Divider></Divider>
                <Img2 src={MiddleImg2} alt=""/>
                <Text2>
                    <Title>Our Commitment</Title>
                    <P1>
                       We are dedicated to safety, reliability, and efficiency, striving to promote a healthy and professional 
                       environment within the workplace. Employee progression and education is at the core of our business, and 
                       we stand by our team, and our clients to deliver the best possible results.
                    </P1>
                    
                </Text2>

             </Div3>
            

        </Div2>
    </Container>
      
    </>
  );
}

const Div3 = styled.div`
padding-top: 70px;
padding-left: 83px;
padding-right: 40px;

@media screen and (max-width: 1110px) {
padding-top: 17px;
padding-left: 24px;
padding-right: 24px;
}

@media screen and (max-width: 700px) {
padding-left: 0px;
padding-right: 0px;
padding-top: 300px;
height: 900px;
}

@media screen and (max-width: 400px) {
padding-top: 350px;

}
`
const Container = styled.div`
height: 760px;
display: flex;

@media screen and (max-width: 1110px) {
height: 670px;
}

`
const Div1 = styled.div`
width: 50%;
// background-image: url(${MiddleImg});
// background-size: 100% 100%;
height: 100%;

@media screen and (max-width: 1110px) {
height: 670px;
}


@media screen and (max-width: 700px) {
display: none;
}
`
const Div2 = styled.div`
width: 50%;
height: 100%;

@media screen and (max-width: 1110px) {
height: 670px;
}

@media screen and (max-width: 700px) {
width: 100%;
}
`
const P1 = styled.p`
font-weight: 400;
line-height: 32px;
font-size: 24px;
font-family: "GT America Regular";
margin-top: 16px;

@media screen and (max-width: 1212px) {
font-weight: 400;
line-height: 32px;
font-size: 24px;
margin-top: 10px;

}

@media screen and (max-width: 1150px) {
font-weight: 400;
line-height: 28px;
font-size: 20px;
margin-top: 10px;
}

@media screen and (max-width: 950px) {
font-weight: 400;
line-height: 24px;
font-size: 16px;
margin-top: 10px;

}

@media screen and (max-width: 700px) {
font-weight: 400;
line-height: 24px;
font-size: 16px;
}
`
const Title = styled.h1`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";

@media screen and (max-width: 700px) {
font-weight: 400;
line-height: 32px;
font-size: 24px;
}
@media screen and (max-width: 799px) {
font-weight: 400;
line-height: 32px;
font-size: 24px;
}

`

const Divider = styled.hr`
display: flex;
justify-content: center;
width: 100%;

border: 2px solid #949494;
margin-top: 74px;
margin-bottom: 38px;

@media screen and (max-width: 1336px) {
margin-top: 64px;

}

@media screen and (max-width: 700px) {
display: none;
}
`
const Img2 = styled.img`
display: none;

@media screen and (max-width: 700px) {
display: block;
width: 100%;
margin-top: 24px;
margin-bottom: 20px;
}
`
const Text1 = styled.p`
@media screen and (max-width: 700px) {
margin-right: 16px;
margin-left: 16px;
}
`
const Text2 = styled.p`
@media screen and (max-width: 700px) {
margin-right: 16px;
margin-left: 16px;
`

export default Experience 