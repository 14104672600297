import React, {useState, useEffect} from "react"
import SocialLinks from "../constants/socialLinks"
import styled from "styled-components";
import Hand from "../assets/hand.png"
import Hand1 from "../assets/about-image-4_d.jpg"
import Hand2 from "../assets/about-image-4_m.jpg"
import BottomImg from "../assets/about-image-5_d.jpg"
import BottomImg2 from "../assets/about-image-5_m.jpg"
import Footer from "../components/Footer"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



function Qualifications({fluid}) {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "about-image-4_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)
    
  return (
    <>
    <Container>
      <Div1>
          <Title>Our Qualifications</Title>
            <P>
                We are fully certified and committed to practices that promote health, 
                safety and professionalism. Each of our team members completes a level one 
                first aid course, hearing tests, fall arrest and confined space training, all 
                which contribute to the dependability our company prides itself on.
            </P>
          <Img2>
          <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
          </Img2>

            <Title2>
                Certification
            </Title2>

                 <Container3>
                   <List1>
                    <Ul>
                      <Li>
                          Fall Protection
                      </Li>
                      <Li>
                          Confined Spaces
                      </Li>
                      <Li>
                          WHMIS
                      </Li>
                      <Li>
                          First Aid
                      </Li>
                   </Ul>
                </List1>

                <List2>
                    <Ul>
                       <Li>
                          P.O.S.T
                      </Li>
                      <Li>
                          Scissor Lift
                      </Li>
                      <Li>
                          Boom Lift
                      </Li>
                    </Ul>
                </List2>
              </Container3>
          
      </Div1>

      <Div2>
        <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
      </Div2>
    </Container>
     {/* <ContImg> */}
      <Img1 src={BottomImg} alt=""/>

     {/* </ContImg> */}

    </>
  );
}

const ContImg = styled.div`
// width: 100%;
// height: 600px;
// background-image: url(${BottomImg});
// background-size: 100% 100%;

// @media screen and (max-width: 1110px) {
// height: 400px;
// }

// @media screen and (max-width: 700px) {
// height: 350px;
// background-image: url(${BottomImg2});
// }
`

const Img1 = styled.img`
height: auto;
width: 100%;
padding-bottom: 0px;
`

const Img2 = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
height: 250px;
margin-left: 16px;
margin-right: 16px;
// background-image: url(${Hand1});
// background-size: 100% 100%;
}

`


const Img3 = styled.img`
width: 100%;

@media screen and (max-width: 700px) {
display: none;
}
`

const Img4 = styled.img`
display: none;


@media screen and (max-width: 700px) {
display: block;
width: 100%;
}
`

const Title2 = styled.h3`
color: #fff;
font-family: "GT America Bold";
font-weight: bold;
font-size: 16px;
line-height: 24px;
margin-top: 51px;
margin-bottom: 22px;

@media screen and (max-width: 700px) {
margin-top: 19px;
margin-bottom: 10px;
margin-left: 16px;
margin-right: 16px;

}
`

const Container3 = styled.div`
display: flex;

`

const Ul = styled.ul`
list-style-type: none;
color: #fff;
font-family: "GT America Regular";
font-weight: 400;
font-size: 16px;
line-height: 24px;
width: 199px;
margin-right: 24px;

@media screen and (max-width: 700px) {
width: 163px;
margin-right: 16px;
margin-left: 16px;

}


`

const List1 = styled.div`
@media screen and (max-width: 700px) {
width: 163px;

}
`
const List2 = styled.div`
@media screen and (max-width: 700px) {
padding-bottom: 60px;
}

`
const Li = styled.li`

`


const Title = styled.div`
color: #fff;
font-family: "GT America Bold";
font-weight: 700;
font-size: 40px;
line-height: 48px;
margin-top: 95px;

@media screen and (max-width: 1110px) {
font-size: 24px;
line-height: 32px;
margin-top: 40px;
}

@media screen and (max-width: 700px) {
padding-top: 30px;
margin-left: 16px;
margin-right: 16px;

}
`
const P = styled.p`
color: #fff;
font-family: "GT America Regular";
font-weight: 400;
font-size: 24px;
line-height: 32px;
margin-top: 19px;
margin-right: 80px;


@media screen and (max-width: 1110px) {
font-size: 16px;
line-height: 24px;
margin-top: 10px;
margin-bottom: 16px;
margin-right: 50px;
}

@media screen and (max-width: 700px) {
margin-right: 16px;
margin-left: 16px;

}
`
const Container = styled.div`
height: 700px;
display: flex;
background-color: #0D6DA3;

@media screen and (max-width: 1110px) {
height: 500px;
}

@media screen and (max-width: 700px) {
display: block;
height: 700px;
margin-top: 500px;

}

@media screen and (max-width: 630px) {
margin-top: 450px;

}
@media screen and (max-width: 550px) {
margin-top: 410px;

}

@media screen and (max-width: 397px) {
margin-top: 440px;

}
`

const Div1 = styled.div`
width: 50%;
margin-left: 64px;

@media screen and (max-width: 1110px) {
margin-left: 24px;
}

@media screen and (max-width: 700px) {
width: 100%;
margin-left: 0px;

}
`
const Div2 = styled.div`
width: 50%;
margin-right: 64px;

display:block;
margin-top: 95px;
height:400px;
// background-image: url(${Hand1});
// background-size: 100% 100%;

@media screen and (max-width: 1110px) {
margin-right: 24px;
height: 264px;
margin-top: 40px;

}

@media screen and (max-width: 700px) {
display: none;

}
`

const Div3 = styled.div`
display: none;

width: 50%;
margin-right: 64px;



@media screen and (max-width: 1110px) {
margin-right: 24px;
height: 264px;
margin-top: 40px;

}

@media screen and (max-width: 700px) {
display:block;
height:350px;
background-image: url(${Hand1});
background-size: 100% 100%;
}
`


export default Qualifications